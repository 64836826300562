<template>
  <div class="setting-resource">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="header-team mt-3" v-else>
      <div class="">
        <v-switch
          :label="
            !activeResource
              ? $i18n.locale == 'fr'
                ? 'Ressources Désactivés'
                : 'Disabled Resource'
              : $i18n.locale == 'fr'
              ? 'Ressources Actifs'
              : 'Enable Resource'
          "
          color="#5C2DD3"
          @change="handlClickActive"
          v-model="activeResource"
          :class="{ 'color-bold': activeResource }"
          class="ml-2 bold-700"
          hide-details
          :true-value="1"
          :false-value="0"
        >
        </v-switch>
      </div>
      <v-list
        dense
        class="list-ensemble mt-3 pt-2"
        v-if="getResource && getResource.length"
      >
        <v-list-item
          class="list-ensemble-item mb-3 mr-3"
          v-for="(resource, index) in getResource"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title class="flex center">
              <div
                class=" text-capitalize font-text font-sz-14 bold-700 text-overflow-setting"
              >
                {{ resource.designation }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              class="icon-action mr-2"
              small
              outlined
              color="#5C2DD3"
              :title="$t('update')"
              @click.prevent.stop="handleUpdateClick(resource)"
            >
              <font-awesome-icon class="img-h-19" icon="pencil-alt" />
            </v-btn>
          </v-list-item-action>
          <v-list-item-action class="ml-0">
            <v-btn
              class="icon-action"
              :title="$t('deleted')"
              small
              outlined
              color="#5C2DD3"
              @click.prevent.stop="handleDeleteClick(resource)"
            >
              <font-awesome-icon class="img-h-19" icon="trash-alt" />
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="div-aucun-list">
        <div class="titre">
          {{ $i18n.locale === 'fr' ? 'Aucune ressource' : 'No resource' }}
        </div>
      </div>
      <div class="footer-style-table" v-if="getResource && getResource.length">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- delete resource -->
    <v-dialog
      v-model="modalDeletedRessource"
      max-width="700"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer une ressource'
                : 'Delete resource'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalDeletedRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'la ressource :' : 'the resource :'
              })
            }}
            <strong class="text-capitalize">
              {{
                resourceToDeleted && resourceToDeleted.designation
                  ? resourceToDeleted.designation
                  : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getResourceError" class="error-msg">
              <ul v-if="Array.isArray(getResourceError)">
                <li v-for="(e, index) in getResourceError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getResourceError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeletedResource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalDeletedRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- update resource   -->
    <v-dialog
      v-model="modalUpdateRessource"
      max-width="900"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Modifier une ressource' : 'Edit resource'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('modalUpdateRessource')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text mt-2">
          <v-form
            ref="modalUpdateRessource"
            class="mt-2"
            v-if="resourceToUpdate"
          >
            <v-row class="row">
              <v-col>
                <!-- NOM RESOURCE -->
                <!-- <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="resourceToUpdate.designation"
                  item-color="#5C2DD3"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                >
                </v-text-field> -->
                <!-- DESCRIPTION -->
                <!-- <v-textarea
                  dense
                  label="Description"
                  v-model="resourceToUpdate.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea> -->
                <!-- TYPE RESOURCE -->
                <v-autocomplete
                  color="#5C2DD3"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="resourceToUpdate.type"
                  :items="getTypeResource"
                  :persistent-placeholder="true"
                  disabled
                  :label="
                    $i18n.locale === 'fr' ? 'Type ressources' : 'Resource type'
                  "
                  item-text="designation"
                  :loading="getTypeResourceLoading"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr'
                          ? 'type ressources'
                          : 'resource type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                resourceToUpdate &&
                  resourceToUpdate.type &&
                  resourceToUpdate.fields &&
                  resourceToUpdate.fields.length
              "
            >
              <v-row
                v-for="field in resourceToUpdate.fields"
                :key="
                  'field-update-' + field.id + `${resourceToUpdate.type.id}`
                "
              >
                <v-col>
                  <div class="value">
                    <EditableCustomFieldForm
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="resourceToUpdate.fields"
                      :fieldKeyEdit="false"
                      @updateFunction="updateFunction"
                      :required="field.required"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- ACTIVE / DESACTIVE RESOURCE -->
            <v-switch
              :label="
                !resourceToUpdate.active
                  ? $i18n.locale == 'fr'
                    ? 'Désactivé'
                    : 'Disabled'
                  : $i18n.locale == 'fr'
                  ? 'Active'
                  : 'Active'
              "
              color="#5C2DD3"
              v-model="resourceToUpdate.active"
              :true-value="1"
              :false-value="0"
              :class="{ 'color-bold': resourceToUpdate.active }"
              class="bold-700 ml-2 margin-switch-input"
            >
            </v-switch>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getResourceLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleValiderUpdateRessource"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('modalUpdateRessource')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm')
  },
  data() {
    return {
      initLoading: true,
      loading: false,
      resourceToDeleted: null,
      modalDeletedRessource: false,
      resourceToUpdate: null,
      modalUpdateRessource: false,
      resourceToUpdateOriginal: null,
      errorMessages: null,
      loadingOpen: false,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ],
      activeResource: 1
    }
  },

  methods: {
    ...mapActions([
      'fetchAllResource',
      'updateResource',
      'deletedResource',
      'resetErrorResource',
      'fetchOneTypeResource',
      'fetchAllTypeResource'
    ]),
    handlClickActive(active) {
      this.page = 1
      this.fetchAllResource({
        page: this.page,
        per_page: this.per_page,
        active: active
      })
    },
    pagination(pagination) {
      this.page = pagination
      this.fetchAllResource({
        page: this.page,
        per_page: this.per_page,
        active: this.activeResource
      })
    },
    changePerPage() {
      this.fetchAllResource({
        page: this.page,
        per_page: this.per_page,
        active: this.activeResource
      })
    },
    updateFunction(data) {
      this.resourceToUpdate.fieldsUpdate = data
    },
    async closeDialog(ref) {
      this[ref] = false
      if (ref == 'modalUpdateRessource') {
        this.$refs.modalUpdateRessource.resetValidation()
        this.fetchAllResource({
          page: this.page,
          per_page: this.per_page,
          active: this.activeResource
        })
      }
      this.resourceToUpdate = null
      this.resetErrorResource()
    },
    handleDeleteClick(resource) {
      this.resourceToDeleted = resource
      this.modalDeletedRessource = true
    },
    async handleDeletedResource() {
      this.loading = true
      const response = await this.deletedResource(this.resourceToDeleted.id)
      if (response) {
        this.closeDialog('modalDeletedRessource')
        this.fetchAllResource({
          page: this.page,
          per_page: this.per_page,
          active: this.activeResource
        })
      }
      this.loading = false
    },
    handleUpdateClick(resource) {
      this.resourceToUpdate = { ...resource }
      this.resourceToUpdateOriginal = resource
      this.modalUpdateRessource = true
    },
    async handleValiderUpdateRessource() {
      if (this.$refs.modalUpdateRessource.validate()) {
        this.loading = true
        const response = await this.updateResource({
          body: this.resourceToUpdate,
          resource: this.resourceToUpdateOriginal
        })
        if (response) {
          this.closeDialog('modalUpdateRessource')
        }
        this.loading = false
      }
    }
  },
  computed: {
    ...mapGetters([
      'getResource',
      'getResourceLoading',
      'getResourceError',
      'getTypeResource',
      'getOneResource',
      'getOneTypeResource',
      'getTypeResourceLoading',
      'getTypeResourceError',
      'countPaginationResource'
    ]),
    totalPages() {
      if (this.countPaginationResource) {
        return Math.ceil(this.countPaginationResource / this.per_page)
      }
      return this.countPaginationResource
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getTypeResourceError(newValue) {
      this.errorMessages = newValue
    },
    getResourceError(newValue) {
      this.errorMessages = newValue
    }
  },
  async mounted() {
    this.initLoading = true
    await this.fetchAllResource({
      page: this.page,
      per_page: this.per_page,
      active: this.activeResource
    })
    this.fetchAllTypeResource()
    this.initLoading = false
  }
}
</script>
<style scoped lang="scss">
.setting-resource {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      height: 30px;
      min-width: 30px;
    }
  }
  .list-ensemble-item {
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
    border-color: #d8d8d8 !important;
  }
  .list-ensemble {
    max-height: calc(100vh - 356px) !important;
    height: calc(100vh - 356px) !important;
    margin-bottom: 0;
    overflow-y: auto;
    overflow-x: auto;
    padding: 0px 0px;
    cursor: pointer;
  }
  .list-ensemble::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  .list-ensemble::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 4px;
    background-color: rgba(238, 238, 238, 1) !important;
  }
  .list-ensemble::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(189, 189, 189, 1);
  }
}
</style>
<style lang="scss">
.setting-resource {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .v-btn:not(.v-btn--round).v-size--small {
      width: 30px;
      height: 30px;
      min-width: 30px;
    }
  }
}
</style>
